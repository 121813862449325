import Web3 from 'web3'

/*  ropsten testnet  */
// const stakingContractAddress = "0xd951c7d77b3465BB872B0E08a9099464b90136D5";
// const stakingTokenAddress = "0xb877431608d4ba0c9c62ef7521ab221bf6f44c8b";
// const rewardTokenAddress = "0x26fe20de3476c38386f49315d54d12c59cfdff6b";

/*  smart chain */

//BUSD REWARDS -> Stake MLY, Earn BUSD (OLD)
const stakingContractAddress = '0x7994CB02236513d814dBBB0457059da192C9A770' //busd rewards
const stakingTokenAddress = '0x553af511a05fe87476e49374983eeca0d63bca90'
const rewardTokenAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const stakingContractAbi = require('../abi/skatingContract.json')
const stakingTokenAbi = require('../abi/stakingToken.json')
const rewardTokenAbi = require('../abi/rewardToken.json') //busd

//MLY REWARDS -> Stake MLY, Earn MLY
//const mlyStakingContractAddress = '0x3A7B45293069DE9368BDFa6F91D085BE66dAe751' //mly rewards
//const mlyStakingContractAddress = '0xBB72c8817e2E9819a6D6acAD975a5D76DC8a034e';
const mlyStakingContractAddress = "0xe838D552D5a4c49e77da37BC02B9D0088e159ED4";
const mlyStakingTokenAddress = '0x553af511a05fe87476e49374983eeca0d63bca90'
const mlyRewardTokenAddress = '0x553af511a05fe87476e49374983eeca0d63bca90'
const mlyStakingContractAbi = require('../abi/mlyStakingAbi.json')
const mlyRewardTokenAbi = require('../abi/mlyStakingAbi.json')

//BUSD REWARDS -> Stake MLY, Earn BUSD (NEW)
const BUSDStakingContractAddress = "0xA8034ccb53170438d2812b9e2Cf1efa4E89470D3";
const BUSDStakingTokenAddress = '0x553af511a05fe87476e49374983eeca0d63bca90'
const BUSDRewardTokenAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const BUSDStakingContractAbi = require('../abi/busdCAStakingAbi.json')
const BUSDRewardTokenAbi = require('../abi/busdStakingAbi.json')

//BUSD POOL
const createWeb3Instance = web3Provider => {
  return new Web3(web3Provider)
}
const createStakingContractInstance = web3 => {
  return new web3.eth.Contract(stakingContractAbi, stakingContractAddress)
}
const createStakingTokenInstance = web3 => {
  return new web3.eth.Contract(stakingTokenAbi, stakingTokenAddress)
}
const createRewardTokenInstance = web3 => {
  return new web3.eth.Contract(rewardTokenAbi, rewardTokenAddress)
}
const humanReadableAccount = account => {
  return account.slice(0, 6) + '...' + account.slice(account.length - 4)
}



//MLY POOL
const createWeb3InstanceMLY = web3Provider => {
  return new Web3(web3Provider)
}
const createStakingContractInstanceMLY = web3 => {
  return new web3.eth.Contract(mlyStakingContractAbi, mlyStakingContractAddress)
}

const createStakingTokenInstanceMLY = web3 => {
  return new web3.eth.Contract(stakingTokenAbi, mlyStakingTokenAddress)
}

const createRewardTokenInstanceMLY = web3 => {
  return new web3.eth.Contract(mlyRewardTokenAbi, mlyRewardTokenAddress)
}

const humanReadableAccountMLY = account => {
  return account.slice(0, 6) + '...' + account.slice(account.length - 4)
}

//BUSD POOL
const createWeb3InstanceBUSD = web3Provider => {
  return new Web3(web3Provider)
}
const createStakingContractInstanceBUSD = web3 => {
  return new web3.eth.Contract(BUSDStakingContractAbi, BUSDStakingContractAddress)
}

const createStakingTokenInstanceBUSD = web3 => {
  return new web3.eth.Contract(stakingTokenAbi, BUSDStakingTokenAddress)
}

const createRewardTokenInstanceBUSD = web3 => {
  return new web3.eth.Contract(BUSDRewardTokenAbi, BUSDRewardTokenAddress)
}

const humanReadableAccountBUSD = account => {
  return account.slice(0, 6) + '...' + account.slice(account.length - 4)
}

export {
  mlyStakingContractAddress as stakingContractAddressMLY,
  createWeb3InstanceMLY,
  createStakingContractInstanceMLY,
  createStakingTokenInstanceMLY,
  humanReadableAccountMLY,
  createRewardTokenInstanceMLY,

  BUSDStakingContractAddress as stakingContractAddressBUSD,
  createWeb3InstanceBUSD,
  createStakingContractInstanceBUSD,
  createStakingTokenInstanceBUSD,
  humanReadableAccountBUSD,
  createRewardTokenInstanceBUSD,

  stakingContractAddress,
  createWeb3Instance,
  createStakingContractInstance,
  createStakingTokenInstance,
  humanReadableAccount,
  createRewardTokenInstance
}
