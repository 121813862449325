import ARROW from "../../assets/images/arrow.svg";
import WalletLink from "walletlink";
import { ToastContainer, toast } from "react-toastify";
import { MaxUint256 } from "@ethersproject/constants";
import { useCallback, useState, useEffect, useRef } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Web3 from "web3";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';

import {
  createWeb3InstanceMLY,
  createStakingContractInstanceMLY,
  createStakingTokenInstanceMLY,
  humanReadableAccountMLY,
  createRewardTokenInstanceMLY,
  stakingContractAddressMLY,
} from "../../core";

const injected = new InjectedConnector({
  supportedChainIds: [56, 97],
});
// const INFURA_ID = "9aa3d95b3bc440fa88ea12eaa4456161";
const TOKEN_PRICE = 0.000211297;
export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
        //42: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        3: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      },
      network: "binance",
      qrcode: true,
      qrcodeModalOptions: {
        mobileLinks: [
          "metamask",
          "trust",
        ]
      },
    },
  },
  // "custom-walletlink": {
  //   display: {
  //     logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
  //     name: "Coinbase",
  //     description: "Connect to Coinbase Wallet (not Coinbase App)",
  //   },
  //   options: {
  //     appName: "Coinbase", // Your app name
  //     networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  //     chainId: 1,
  //   },
  //   package: WalletLink,
  //   connector: async (_, options) => {
  //     const { appName, networkUrl, chainId } = options;
  //     const walletLink = new WalletLink({
  //       appName,
  //     });
  //     const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
  //     await provider.enable();
  //     return provider;
  //   },
  // },
};

const MLYStake = () => {
  const stakeAmount = useRef(null);
  const unstakeAmount = useRef(null);

  const [status, setStatus] = useState("stake");
  const [stakingStatus, setStakingStatus] = useState("approve");

  const [chainId, setChainId] = useState(0);
  const [provider, setProvider] = useState(undefined);

  const { active, activate, deactivate, account, error } = useWeb3React();

  const [stakingContract, setStakingContract] = useState("");
  const [staketokenContract, setStakeTokenContract] = useState("");
  const [balance, setBalance] = useState();
  const [stakedBalance, setStakedBalance] = useState();
  const [transactionPending, setTransactionPendingToggle] = useState(false);
  const [rewardTokenContract, setRewardTokenContract] = useState("");
  const [poolAmount, setPoolAmount] = useState();
  const [contractTotalSupply, setContractTotalSupply] = useState();
  const [poolPeriod, setPoolPeriod] = useState();
  const [startTime, setStartTime] = useState();
  const [currTimeEpoch, setCurrTimeEpoch] = useState("");
  const [endTimeEpoch, setEndTimeEpoch] = useState("")
  const [reward, setReward] = useState();
  const [elapsed, setElapsed] = useState('');
  const [tvl, setTVL] = useState(0);
  const [poolStart, setPoolStart] = useState(new Date());
  const [poolEnd, setPoolEnd] = useState(new Date());
  const [rewardAmount, setRewardAmount] = useState(0);
  const [apr, setApr] = useState(0);
  const DECIMALS = 18;


  const setMax = (amount) => {
    if (stakeAmount.current) {
      stakeAmount.current.value = amount
    }
  }

  const setMaxWithdraw = (amount) => {
    unstakeAmount.current.value = amount;
  }

  const load = async () => {
    const _web3 = createWeb3InstanceMLY(Web3.givenProvider);
    const _stakingContract = createStakingContractInstanceMLY(_web3);
    const _sTokenContract = createStakingTokenInstanceMLY(_web3);
    const _rTokenContract = createRewardTokenInstanceMLY(_web3);

    setStakingContract(_stakingContract);
    setStakeTokenContract(_sTokenContract);
    setRewardTokenContract(_rTokenContract);

    const _poolAmount = await _stakingContract.methods.poolAmount().call();

    const tvl = await _stakingContract.methods.totalSupply().call();

    setTVL(tvl)

    setPoolAmount(_web3.utils.fromWei(_poolAmount));

    const _reward = await _stakingContract.methods.getReward(account).call();

    console.log("REWARD IS", _reward, typeof(_reward))

    setReward(Math.floor((_reward)/1000000000));

    const _balanceWei = await _sTokenContract.methods.balanceOf(account).call();
    setBalance(_balanceWei / 10 ** 9);

    const _stakedbalance = await _stakingContract.methods
      .balanceOf(account)
      .call();

    setStakedBalance(_stakedbalance / 10 ** 9);

    const _poolPeriod = await _stakingContract.methods.poolPeriod().call();

    setPoolPeriod(_poolPeriod);

    const _contractTotalSupply = await _stakingContract.methods
      .totalSupply()
      .call();
    setContractTotalSupply(_contractTotalSupply / 10 ** 9);

    const _startTime = await _stakingContract.methods.startTime().call();

    setStartTime(_startTime);

    //console.log("START TIME", _startTime);
    console.log("CURRENT TIME EPOCH", Math.floor(new Date().getTime() / 1000))
    const currTime = Math.floor(new Date().getTime() / 1000);
    const stakeDisabled = await _stakingContract.methods.disableStake().call();
    console.log("IS DSABLED", stakeDisabled)
    setElapsed(stakeDisabled);
    //const _allowance = await _sTokenContract.methods
    //  .allowance(account, stakingContractAddressMLY)
    //  .call();
    //if (_allowance - MaxUint256 === 0) {
    //  setStakingStatus("withdraw");
    //}

    console.log("SET STAKING", stakingStatus)

    getApr();
  };

  useEffect(() => {
    if (active) {
      load();
    }
  }, [active]);


  const disconnect = useCallback(() => {
    if (provider && provider.close) {
      provider.close();
    }
  }, [provider]);

  const reset = useCallback(
    (error) => {
      if (error) {
        console.error(error);
      }
      setProvider(undefined);
    },
    [setProvider]
  );

  const modal = new Web3Modal({
    providerOptions,
    cacheProvider: true,
  });

  const connectProvider = useCallback(
    (provider) => {
      console.log("[Web3] Setting up provider");
      const web3 = new Web3(provider);
      Promise.all([
        web3.eth.net.getId().then((net) => setChainId(net.chainId)),
      ]);
    },
    [setChainId]
  );


  const connect = useCallback(() => {
    modal
      .connect()
      .then((provider) => {
        walletConnect(activate);
        provider.on("accountsChanged", ([account]) => {
          console.log("[Web3] accountsChanged");
          connectProvider(provider);
        });

        provider.on("chainChanged", (chainId) => {
          setChainId(parseInt(chainId, 16));
          connectProvider(provider);
        });

        provider.on("connect", ({ chainId }) => {
          console.log("[Web3] Connect");
          setChainId(chainId);
          connectProvider(provider);
        });

        provider.on("disconnect", (error) => {
          console.log("[Web3] Disconnect");
          reset(error);
          console.log(error);
        });
        setProvider(provider);
        connectProvider(provider);
      })
      .catch((error) => console.error(error));
  }, []);

  const walletdeactivate = useCallback(() => {
    disconnect();
    reset();
  }, [disconnect, reset]);

  const handleConnectWallet = () => {
    if (active) {
      walletdeactivate();
      walletDisconnect(deactivate);
    } else connect();
  };

  const walletConnect = async (activate) => {
    try {
      await activate(injected);
      console.log(active);
    } catch (ex) {
      console.log(ex);
    }
  };

  const walletDisconnect = async (deactivate) => {
    try {
      modal.clearCachedProvider();

      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  };

  const getApr = async () => {
    const _web3 = createWeb3InstanceMLY(Web3.givenProvider);
    const stakingContract = createStakingContractInstanceMLY(_web3);
    let poolPeriod = await stakingContract.methods.poolPeriod().call();
    //console.log("RAW", poolPeriod)
    poolPeriod = (poolPeriod / 86400).toFixed(2);
    poolPeriod = parseFloat(poolPeriod);
    let reward = await stakingContract.methods.getReward(account).call();
    reward = reward * 10 ** -18;

    const stakedBalance = await stakingContract.methods
      .balanceOf(account)
      .call();

    let info;
    await axios.get('https://api.pancakeswap.info/api/v2/tokens/0x553af511a05fe87476e49374983eeca0d63bca90').then(async payload => {
      info = payload.data.data.price;
      info = parseFloat(info)
      //console.log(")))))))", info, typeof (info))
      const returnPct = (reward / Math.floor(stakedBalance * 10 ** -9) * info) * 100;
      const prix = (stakedBalance * 10 ** -9) * info;

      //console.log("REWARDS", reward)
      //console.log("STAKING", stakingContract)
      //console.log("^^^^^", poolPeriod, Math.floor(stakedBalance * 10 ** -9 ), reward)
      let apr = (365 * reward) / poolPeriod;
      apr = (apr / prix) * 100
      //console.log("Pool Period", poolPeriod)
      setApr(parseFloat(apr.toFixed(3)))
    }).catch(e => {
      console.log(e)
    })
  }

  const handleApprove = () => {
    if (!stakingContract) {
      toast.warn("Connect wallet");
    } else {
      setTransactionPendingToggle(true);
      const id = toast.loading("Transaction pending");

      staketokenContract.methods
        // rewardTokenContract.methods
        .approve(stakingContractAddressMLY, MaxUint256)
        .send({ from: account })
        .once("receipt", (receipt) => {
          toast.update(id, {
            render: "Successfully Approved",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          console.log(receipt);
          setStakingStatus("withdraw");
          setTransactionPendingToggle(false);
        })
        .on("error", function (error) {
          let errorMessage = "";
          if (error.code === 4001) {
            errorMessage =
              "MetaMask Tx Signature: User denied transaction signature.";
          }
          if (error.code === -32603) {
            errorMessage =
              "execution reverted: Ownable: caller is not the owner";
          }
          console.log("error", error);
          toast.update(id, {
            render: errorMessage || "error",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          setTransactionPendingToggle(false);
        });
    }
  };
  const handleStake = () => {
    if (!stakingContract) {
      toast.warn("Connect wallet");
    } else {
      if (stakeAmount.current.value === 0 || stakeAmount.current.value === "") {
        toast.warn("Enter amount");
      } else {
        const id = toast.loading("Transaction pending");
        setTransactionPendingToggle(true);
        stakingContract.methods
          .stake(stakeAmount.current.value + "000000000")
          .send({ from: account })
          .once("receipt", (receipt) => {
            toast.update(id, {
              render: "Successfully Staked",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            console.log(receipt);
            setStakingStatus("withdraw");
            setTransactionPendingToggle(false);
            load();
          })
          .on("error", function (error) {
            let errorMessage = "";
            console.log("ERROR IS", error)
            if (error.code === 4001) {
              errorMessage =
                "MetaMask Tx Signature: User denied transaction signature.";
            }
            if (error.code === -32603) {
              errorMessage =
                "execution reverted: Ownable: caller is not the owner";
            }
            console.log("error", error);
            toast.update(id, {
              render: errorMessage || "error",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
            setTransactionPendingToggle(false);
          });
      }
    }
  };

  const handleUnstake = () => {
    if (!stakingContract) {
      toast.warn("Connect wallet");
    } else {
      if (
        unstakeAmount.current.value === 0 ||
        unstakeAmount.current.value === ""
      ) {
        toast.warn("Enter amount");
      } else {
        const id = toast.loading("Transaction pending");
        setTransactionPendingToggle(true);
        stakingContract.methods
          .withdraw(unstakeAmount.current.value + "000000000")
          .send({ from: account })
          .once("receipt", (receipt) => {
            toast.update(id, {
              render: "Successfully Unstaked",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            console.log(receipt);
            setTransactionPendingToggle(false);
            load();
          })
          .on("error", function (error) {
            let errorMessage = "";
            if (error.code === 4001) {
              errorMessage =
                "MetaMask Tx Signature: User denied transaction signature.";
            }
            if (error.code === -32603) {
              errorMessage =
                "execution reverted: Ownable: caller is not the owner";
            }
            console.log("error", error);
            toast.update(id, {
              render: errorMessage || "error",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
            setTransactionPendingToggle(false);
          });
      }
    }
  };

  const handleSetWindow = async (value) => {
    try {
      await stakingContract.methods.setDisableStake(value).send({ from: account })
      console.log("exec")
    }
    catch (e) {
      console.log(e)
    }
    console.log("DONE")
  }


  const setPeriod = async () => {
    if (!poolStart || !poolEnd || !poolAmount) {
      alert("MUST SET BOTH START AND END!")
      return;
    }
    const startEpoch = new Date(poolStart).getTime();
    const endEpoch = new Date(poolEnd).getTime();
    const totalReward = rewardAmount * 10 ** DECIMALS;

    console.log("start epoch", startEpoch);
    console.log("end epoch", endEpoch);

    const elapsedTime = endEpoch - startEpoch;

    //const endTimePeriodEpoch = startEpoch + elapsedTime;

    console.log("Start", startEpoch, "End", elapsedTime, "Total", startEpoch + elapsedTime)
    //await stakingContract.methods.startStake(startEpoch, elapsedTime, totalReward.toString()).send({ from: account })

    //console.log("elapsed EPOCH", startEpoch + elapsedTime)
  }


  return (
    <div className="font-novaSqure bg-secondary min-h-screen text-white xs:p-16 p-2">
      <div className="flex justify-end">
        <button onClick={handleConnectWallet}>
          {error instanceof UnsupportedChainIdError
            ? "wrong chain"
            : active
              ? account
                ? humanReadableAccountMLY(account)
                : "Connect wallet"
              : "Connect wallet"}
        </button>
      </div>
      <div className="flex justify-between text-lg py-10 mb-12 max-w-2xl mx-auto ">
        <a href="/">MLY STAKING <span><img height="80px" width="80px" src="https://metaland.metaclub.biz/static/media/logo.c8d64be3.png" /></span></a>
        <a target="_blank" href="https://t.me/MetaLandYield">TG</a>
        <a target="_blank" href="https://metaland.metaclub.biz/">WEBSITE</a>
      </div>
      <div>
        {
          active && account === "0xE1bC3aBF8A1549bD9452C0b4CDa50F66504DE214"
            ?
            <div className="bg-third xs:p-10 p-4 flex flex-col max-w-2xl mx-auto rounded-lg my-4">
              <>Admin view</>
              <div>
                <p>TVL: {tvl} tokens</p>
                <p>REWARD: MLY</p>
                <p>Staking window closed: {elapsed ? "True" : "False"}</p>
                <p>Pool start: {new Date(startTime * 1000).toLocaleString()}</p>
                <p>Pool end: {new Date(
                  (parseInt(startTime) + parseInt(poolPeriod)) * 1000
                ).toLocaleString()}</p>
              </div>
              <p>{""}</p>
              <p className="text-underline">ADMIN CONTROLS</p>
              <p>{""}</p>
              <div className="my-4 mb-8 mt-8">
                <p>Set staking window closed: </p>
                <button
                  className={
                    "text-black px-6 py-2 " +
                    (status === "stake" ? "bg-white" : "bg-forth")
                  }
                  onClick={() => handleSetWindow(!elapsed)}
                >Toggle window open</button>

                <p>Set pool start: </p>
                <DateTimePicker
                  onChange={setPoolStart}
                  value={poolStart}
                />


                <p>Set pool end: </p>
                <DateTimePicker
                  onChange={setPoolEnd}
                  value={poolEnd}
                />
                <p>Reward Amount: </p>
                <input
                  className="bg-fifth outline-none rounded-3xl p-3 text-center"
                  value={rewardAmount}
                  onChange={(e) => setRewardAmount(e.target.value)}
                />
              </div>
              <button
                className={
                  "text-black px-6 py-2 " +
                  (status === "stake" ? "bg-white" : "bg-forth")
                }
                onClick={() => setPeriod()}
              >Set</button>
            </div>

            :
            <div className="bg-third xs:p-10 p-4 flex flex-col max-w-2xl mx-auto rounded-lg">
              <div className="text-center">EARN MLY</div>
              <div className="flex justify-center items-center">
                <img
                  src="https://metaland.metaclub.biz/static/media/logo.c8d64be3.png"
                  alt="mly"
                  width="50"
                  className="my-4"
                />
              </div>
              <div className="grid grid-cols-2 w-full mt-5">
                <button
                  className={
                    "text-black px-6 py-2 " +
                    (status === "stake" ? "bg-white" : "bg-forth")
                  }
                  onClick={() => setStatus("stake")}
                >
                  STAKE
                </button>
                <button
                  className={
                    "text-black px-6 py-2  " +
                    (status === "unstake" ? "bg-white" : "bg-forth")
                  }
                  onClick={() => {
                    setStatus("unstake");
                  }}
                >
                  UNSTAKE
                </button>
              </div>
              {status === "stake" ? (
                <div className="flex flex-col gap-10 mt-10">
                  {
                    <div className="justify-between flex">
                      <div>TVL</div>
                      <div>
                        {active
                          ? `${tvl / 10 ** 9} MLY` : null}

                      </div>
                    </div>

                  }
                  {
                    /**
                     *             <div className="justify-between flex">
                                              <div>APY</div>
                                              <div>
                                                {active
                                                  ? reward === 0
                                                    ? "0 "
                                                    : (
                                                      (reward * 365 * 1440 * 100) /
                                                      poolPeriod /
                                                      stakedBalance /
                                                      TOKEN_PRICE
                                                    ).toFixed(5)
                                                  : "--- "}
                                                %
                                              </div>
                                            </div>
                     */

                  }
                  {
                    <div className="justify-between flex">
                      <div>APR</div>
                      <div>
                        {active
                          ? reward === 0
                            ? "0 "
                            :
                            apr
                          : "--- "}
                        %
                      </div>
                    </div>

                  }
                  <div className="justify-between flex">
                    <div>DEPOSIT</div>
                    <div>
                      {active ? parseFloat(stakedBalance).toFixed(2) : "---"} MLY
                    </div>
                  </div>
                  <div className="justify-between flex">
                    <div>PENDING PROFIT</div>
                    <div>
                      {active
                        ? contractTotalSupply === 0
                          ? "0 "
                          : parseFloat(reward).toFixed(2) 
                        : "--- "}
                      {" MLY"}
                    </div>
                  </div>


                  {
                    <div className="justify-between flex">
                      <div>PERIOD</div>
                      <div>
                        {active ? (poolPeriod / 86400).toFixed(2) : "---"} DAYS
                      </div>
                    </div>

                  }
                  <>
                    <div className="justify-between flex">
                      <div>START TIME</div>
                      <div>
                        {active
                          ? startTime === "0"
                            ? "---Not started---"
                            : new Date(startTime * 1000).toLocaleString()
                          : "---"}
                      </div>
                    </div>
                    <div className="justify-between flex">
                      <div>END TIME</div>
                      <div>
                        {active
                          ? startTime === "0"
                            ? "---Not started---"
                            : new Date(
                              (parseInt(startTime) + parseInt(poolPeriod)) * 1000
                            ).toLocaleString()
                          : "---"}
                      </div>
                    </div>
                  </>
                  {console.log("IS ACTIVE", active)}
                  {
                    !elapsed
                      ?
                      null
                      :
                      <div className="flex justify-center items-center">

                        <div>
                          <p>STAKING WINDOW CLOSED</p>
                        </div>
                      </div>
                  }
                  {
                    !elapsed
                    &&
                    <>
                      <div className="justify-between flex">
                        <div>BALANCE</div>
                        <div>{active ? parseFloat(balance).toFixed(2) : "---"} MLY <span><button onClick={() => setMax(Math.floor(parseFloat(balance).toFixed(2)))} class="rounded-tl-lg  bg-maxBtn">MAX</button></span></div>
                      </div>
                      <input
                        className="bg-fifth outline-none rounded-3xl p-3 text-center"
                        ref={stakeAmount}
                      />
                    </>
                  }
                  {
                    !elapsed ?
                      stakingStatus === "approve" ? (
                        <button
                          className={
                            "bg-btnBgOne text-black rounded-2xl py-3 " +
                            (transactionPending
                              ? "cursor-not-allowed"
                              : "cursor-pointer")
                          }
                          onClick={handleApprove}
                        >
                          Approve
                        </button>
                      ) : (
                        <button
                          className={
                            "bg-btnBgOne text-black rounded-2xl py-3 " +
                            (transactionPending
                              ? "cursor-not-allowed"
                              : "cursor-pointer")
                          }
                          onClick={handleStake}
                        >
                          Deposit
                        </button>
                      )
                      :
                      null
                  }
                </div>
              ) : (
                <div className="flex flex-col gap-10 mt-10">
                  {
                    /**
                     *                   <div className="justify-between flex">
                                      <div>APY</div>
                                      <div>
                                        {active
                                          ? reward === 0
                                            ? "0 "
                                            : (
                                              (reward * 365 * 1440 * 100) /
                                              poolPeriod /
                                              stakedBalance /
                                              TOKEN_PRICE
                                            ).toFixed(5)
                                          : "--- "}
                                        %
                                      </div>
                                    </div>
                     */
                  }
                  <div className="justify-between flex">
                    <div>DEPOSIT</div>
                    <div>
                      <div>{active ? parseFloat(stakedBalance).toFixed(2) : "---"} MLY <span><button onClick={() => setMaxWithdraw(Math.floor(parseFloat(stakedBalance).toFixed(2)))} class="rounded-tl-lg  bg-maxBtn">MAX</button></span></div>

                    </div>
                  </div>
                  <div className="justify-between flex">
                    <div>PENDING PROFIT</div>
                    <div>
                      {active
                        ? contractTotalSupply === 0
                          ? "0 "
                          : parseFloat(reward).toFixed(2)
                        : "--- "}
                      {" MLY"}
                    </div>
                  </div>

                  <div className="justify-between flex">
                    <div>* Cannot unstake during staking window</div>
                  </div>
                  <input
                    className="bg-fifth outline-none rounded-3xl p-3 text-center"
                    ref={unstakeAmount}
                  />
                  <button
                    className={
                      "bg-btnBgTwo text-black rounded-2xl py-3 " +
                      (transactionPending ? "cursor-not-allowed" : "cursor-pointer")
                    }
                    onClick={handleUnstake}
                  >
                    WITHDRAW
                  </button>
                </div>
              )}
            </div>
        }

      </div>
      <ToastContainer
        className="fixed top-0"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default MLYStake;








/**
 *     function getReward(address _account) public view returns (uint256) {
        if(_totalSupply == 0) return 0;
        uint256 _poolAmount = _rewardsToken.balanceOf(address(this));
        uint256 _holdingAmount = _rewardsToken.balanceOf(_account);
        uint256 _rewardFull = _poolAmount * _balances[_account] / _totalSupply;
        uint256 reward = _rewardFull.sub(_holdingAmount);
        return reward;
    }
 */