import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { MoralisProvider } from "react-moralis";

/*====== page =======*/

//import BUSDStake from "./pages/stake/xxxxx";
import MLYStake from "./pages/stake/mly";
import BUSDStake from "./pages/stake/busd";
import Dashboard from "./pages/dashboard";
import "react-toastify/dist/ReactToastify.css";

const getLibrary = (provider) => {
  return new Web3(provider);
};

function App() {
  return (
    <MoralisProvider appId="SQT95dKaxs95GPG8Y3tlUcfeg7O89IcJdMCRYmFf" serverUrl="https://ssyeu7vfmhnh.usemoralis.com:2053/server">
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Dashboard />} />
          <Route path="/busd" element={<BUSDStake />} />          
          <Route path="/mly" element={<MLYStake />} />          
        </Routes>
      </BrowserRouter>
    </Web3ReactProvider>
    </MoralisProvider>
  );
}

export default App;
