import { useNavigate } from 'react-router-dom'
import BUSD from '../../assets/images/busd.svg'

const Dashboard = () => {
  let navigate = useNavigate()

  return (
    <div className='font-novaSqure bg-secondary min-h-screen text-white xs:p-16 p-2'>
      <div className='flex justify-between text-lg py-10 mb-12 max-w-2xl mx-auto '>
        <a href='#'>
          MLY STAKING{' '}
          <span>
            <img
              height='80px'
              width='80px'
              src='https://metaland.metaclub.biz/static/media/logo.c8d64be3.png'
            />
          </span>
        </a>
        <a target='_blank' href='https://t.me/MetaLandYield'>
          TG
        </a>
        <a target='_blank' href='https://metaland.metaclub.biz/'>
          WEBSITE
        </a>
      </div>
      <div>
        <div className='flex flex-row items-center justify-center mb-8'>
          <h1 className='text-2xl'>EARN DIVIDENDS</h1>
        </div>

        <div className='flex flex-row items-center justify-center mb-4'>
          <p className='text-green-300'>ACTIVE POOLS</p>
        </div>

        <div
          className='bg-third xs:p-10 p-4 flex flex-col max-w-2xl mx-auto rounded-lg mb-8 cursor-pointer transform transition duration-500 hover:scale-110'
          onClick={() => navigate('/busd')}
        >
          <div className='flex flex-row grid-flow-row-dense mt-5 justify-center items-center gap-5'>
            <div>
              <img height='50px' width='50px' src={BUSD} />
            </div>
            <div>BUSD POOL</div>
          </div>
        </div>

        <div className='flex flex-row items-center justify-center mb-4'>
          <p className='text-red-300'>INACTIVE POOLS</p>
        </div>

        <div className='bg-third cursor-pointer  xs:p-10 p-4 flex flex-col max-w-2xl mx-auto rounded-lg mb-8'
        onClick={() => navigate('/mly')}>
          <div className='flex flex-row grid-flow-row-dense mt-5 justify-center items-center gap-5'>
            <div>
              <img
                height='50px'
                width='50px'
                src='https://metaland.metaclub.biz/static/media/logo.c8d64be3.png'
              />
            </div>
            <div>MLY POOL</div>
          </div>
        </div>

        <div className='bg-third xs:p-10 p-4 flex flex-col max-w-2xl mx-auto rounded-lg mb-8'>
          <div className='flex flex-row grid-flow-row-dense mt-5 justify-center items-center gap-5'>
            <div>
              <img
                height='50px'
                width='50px'
                src='https://upload.wikimedia.org/wikipedia/en/e/ed/Nyan_cat_250px_frame.PNG'
              />
            </div>
            <div>NFT REWARDS POOL</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
